<template>
  <div>
    <div class="title">
      <div class="warp">
        <p>智能密码器Y05A</p>
        <el-radio-group v-model="radio" @input="getValue">
          <el-radio label="1">详情</el-radio>
          <el-radio label="2">参数</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div v-show="show">
      <img src="../../assets/mock/Y05/Apple.jpg" alt="" />
    </div>
    <div v-show="!show"><img src="../../assets/mock/Y05//12.jpg" alt="" /></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      radio: "1",
      show: true,
    };
  },
  methods: {
    getValue(value) {
      if (value == 1) {
        this.show = true;
      } else {
        this.show = false;
      } 
    },
  },
};
</script>

<style lang="scss" scoped>
.isActive {
  color: #3cb59e !important;
}
.title {
  width: 100%;
  position: absolute;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  div {
    p {
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3.25rem;
  }
}
img {
  width: 100%;
}
::v-deep .el-radio__input {
  display: none;
}
::v-deep .el-radio__label {
  color: #fff;
}
</style>
